<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="getPurchaseRecord">
                    <v-card :elevation="1" color='grey lighten-3'>
                        <v-card-text class="py-0">
                            <v-row dense>
                                <v-col cols="2">
                                    <v-row no-gutters style="margin-top: 3px;color: #000;">
                                        <v-col cols="3">Type</v-col>
                                        <v-col cols="9">
                                            <v-combobox 
                                                v-model="searchType"
                                                dense
                                                outlined
                                                hide-details
                                                :items="['All', 'By Supplier', 'By Employee']"
                                            >
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="3"  v-if="searchType == 'By Supplier'">
                                    <v-row no-gutters style="margin-top: 3px;color: #000;">
                                        <v-col cols="4">Supplier</v-col>
                                        <v-col cols="8">
                                            <v-combobox 
                                                dense
                                                outlined
                                                hide-details
                                                v-model="supplier"
                                                @focus="$store.dispatch('supplier/getSuppliers')"
                                                :items="$store.getters['supplier/suppliers']"
                                                :loading="$store.getters['supplier/loading']"
                                                item-text="display_text"
                                                item-value="id"
                                            >
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="3"  v-if="searchType == 'By Employee'">
                                    <v-row no-gutters style="margin-top: 3px;color: #000;">
                                        <v-col cols="4">Employee</v-col>
                                        <v-col cols="8">
                                            <v-combobox 
                                                dense
                                                outlined
                                                hide-details
                                                v-model="employee"
                                                @focus="$store.dispatch('employee/getEmployees')"
                                                :items="$store.getters['employee/employees']"
                                                item-text="display_text"
                                                item-value="id"
                                            >
                                            </v-combobox>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="6">
                                    <v-row dense style="margin-top: -2px;">
                                        <v-col cols="6">
                                            <v-row no-gutters>
                                                <v-menu>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            hide-details
                                                            v-model="purchase.dateFrom"
                                                            label="Form Date"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                        >
                                                        <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="purchase.dateFrom"></v-date-picker>
                                                </v-menu>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-menu>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        v-model="purchase.dateTo"
                                                        label="To Date"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                    <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker v-model="purchase.dateTo"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn height="30px" type="submit" dark color="light-blue darken-2" :loading="searchLoading">Search</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense v-if="show">
            <v-col cols="12">
                <v-btn height="26px" v-on:click.prevent="print">Print</v-btn>
            </v-col>
            <v-col cols="12" id="invoiceContent">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>Invoice No</th>
                                <th>Date</th>
                                <th>Supplier Name</th>
                                <th>Sub Total</th>
                                <th>Vat</th>
                                <th>Discount</th>
                                <th>Other Cost</th>
                                <th>Total</th>
                                <th>Paid</th>
                                <th>Due</th>
                                <th class="hide">Note</th>
                                <th class="hide">Save by</th>
                                <th class="hide">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(purchase, ind) in $store.getters['purchase/purchases']" :key="ind">
                                <td>{{ purchase.invoice_id }}</td>
                                <td>{{ purchase.purchase_date }}</td>
                                <td>{{ purchase.supplier? purchase.supplier.name : '' }}</td>
                                <td class="text-right">{{ purchase.sub_total }}</td>
                                <td class="text-right">{{ purchase.vat }}</td>
                                <td class="text-right">{{ purchase.discount }}</td>
                                <td class="text-right">{{ purchase.others }}</td>
                                <td class="text-right">{{ purchase.total }}</td>
                                <td class="text-right">{{ purchase.paid }}</td>
                                <td class="text-right">{{ purchase.due }}</td>
                                <td class="text-right hide" style="width:15%">{{ purchase.note }}</td>
                                <td class="text-right hide">{{ purchase.added_by ? purchase.added_by.name :'' }}</td>
                                <td style="width:8%" class="text-center hide">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon x-small @click="printInvoice(purchase.id)" v-on="on">mdi-printer</v-icon>
                                        </template>
                                        <span>Print</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon x-small @click="$router.push(`/purchase/${purchase.id}`)" color="success" v-on="on">mdi-circle-edit-outline</v-icon>
                                        </template>
                                        <span>Edit</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon x-small @click="purchaseId = purchase.id; $refs.confirmDialog.dialog = true" color="error" v-on="on">mdi-delete-circle-outline</v-icon>
                                        </template>
                                        <span>Delete</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" class="text-right" style="font-weight:bold">Total</td>
                                <td class="text-right" style="font-weight:bold">{{ subTotal }}</td>
                                <td class="text-right" style="font-weight:bold">{{ vat }}</td>
                                <td class="text-right" style="font-weight:bold">{{ discount}}</td>
                                <td class="text-right" style="font-weight:bold">{{ others }}</td>
                                <td class="text-right" style="font-weight:bold">{{ total }}</td>
                                <td class="text-right" style="font-weight:bold">{{ paid }}</td>
                                <td class="text-right" style="font-weight:bold">{{ due }}</td>
                                <td class="hide"></td>
                                <td class="hide"></td>
                                <td class="hide"></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <confirm-dialog ref="confirmDialog" @confirm="deletePurchase"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import confirmDialog from "../../components/Confirm";
export default {
    name: 'PurchaseRecord',

    components: {
		"confirm-dialog": confirmDialog,
    },

    data: ()=> ({
        searchType: null,

        purchase: {
            supplier_id: null,
            employee_id: null,
            dateFrom: new Date().toISOString().substr(0, 10),
            dateTo: new Date().toISOString().substr(0, 10),
        },
        purchaseRecord: [],

        supplier: null,
        employee: null,
        searchLoading: false,
        apiBasePath: window.apiBaseURL,
        show: false,
        purchaseId: null
    }),

    watch: {
        supplier(supplier) {
            if (supplier == null) return
            this.purchase.supplier_id = supplier.id;
        },

        employee(employee) {
            if(employee == null) return
            this.purchase.employee_id = employee.id;
            
        },
        
    },

    computed: {
        subTotal() {
            return this.$store.getters['purchase/purchases'].reduce((p, c) => { return +p + +c.sub_total }, 0)
        },
        
        vat() {
            return this.$store.getters['purchase/purchases'].reduce((p, c) => { return +p + +c.vat }, 0)
        },
        
        discount() {
            return this.$store.getters['purchase/purchases'].reduce((p, c) => { return +p + +c.discount }, 0)
        },
        
        others() {
            return this.$store.getters['purchase/purchases'].reduce((p, c) => { return +p + +c.others }, 0)
        },
        
        total() {
            return this.$store.getters['purchase/purchases'].reduce((p, c) => { return +p + +c.total }, 0)
        },
        
        paid() {
            return this.$store.getters['purchase/purchases'].reduce((p, c) => { return +p + +c.paid }, 0)
        },
        
        due() {
            return this.$store.getters['purchase/purchases'].reduce((p, c) => { return +p + +c.due }, 0)
		},
    },

    created() {
        this.setStyle();
    },

    methods: {
        getPurchaseRecord() {

            this.searchLoading = true;

            this.$store.dispatch('purchase/getPurchases', this.purchase);

            this.searchLoading = false;
            
            this.show = true;
        },

        deletePurchase() {
            this.$store.dispatch('purchase/deletePurchaseRecord', { id: this.purchaseId });
            this.$refs.confirmDialog.dialog = false;
        },

        printInvoice(id) {
            this.$router.push(`/purchase-invoice/${id}`);
        },

        async print(){
            let invoiceContent = document.querySelector('#invoiceContent').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            let companyProfile = this.$store.getters['companyProfile/company']
            let companyImage = companyProfile != null ? this.$store.getters['companyProfile/company'].image : ''
            let ImagePath = companyImage ? this.apiBasePath + '/' + companyImage : '';
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Investigation Payment Slip</title>
                    <style>
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-2">
                                                    <img src="`+ImagePath+`" alt="Logo" style="height:90px; width:90%;object-fit: contain;" />
                                                </div>
                                                <div class="col-xs-10">
                                                    <strong style="font-size:18px;">${companyProfile != null ? companyProfile.company_name : ''}</strong><br>
                                                    <p style="white-space:pre-line;margin:0px">Address : ${companyProfile != null ? companyProfile.address : ''}</p>
                                                    <p style="margin:0px">Mobile : ${companyProfile != null ? companyProfile.contact_us : ''}</p>
                                                    <p style="margin:0px">E-Mail : ${companyProfile != null ? companyProfile.email : ''}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    Purchase Record
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);
            
            let invoiceStyle = printWindow.document.createElement('style');
            invoiceStyle.innerHTML = this.style.innerHTML;
            printWindow.document.head.appendChild(invoiceStyle);
            printWindow.moveTo(0, 0);
            
            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle(){
            this.style = document.createElement('style');
            this.style.innerHTML = `
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }
                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
            document.head.appendChild(this.style);
        },
    }
}
</script>

<style lang="scss" scoped>
   .v-icon.v-icon {
       font-size: 18px !important;
       top: -3px !important;
   }
   .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
        font-size: 11px;
        padding: 0px 1px;
        background: #607D8B;
        color: #fff;
        border: 1px solid #ccc;
        text-align: center;
        height: 24px !important;
   }
   .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 20px !important;
        padding: 0px 5px !important;
        font-size: 12px !important;
        border: 1PX solid #ccc !important;
   }
</style>